import React, { useState } from "react"
import Axios from "axios"
import Recaptcha from "react-recaptcha"
import Button from "react-bootstrap/Button"

import useForm from "./Hooks/useForm"
import validate from "./Validation/TravelClub"

import Message from "../Message"
import Icon from "../Icon"
import Feedback from "./Feedback"

import "./styles.scss"

const API = process.env.API
const recaptchaKey = process.env.GOOGLE_RECAPTCHA_KEY
let recaptchaInstance

const TravelClubForm = () => {
  const [message, setMessage] = useState({})
  const [isSubmitting, setIsSubmitting] = useState(false)
  const {
    handleChange,
    handleCheckboxChange,
    handleSubmit,
    handleReCaptcha,
    resetReCaptcha,
    values,
    errors,
  } = useForm(submit, validate)

  function submit() {
    setMessage({})
    setIsSubmitting(true)
    Axios.post(`${API}/forms/travelclub`, values)
      .then(() => {
        setIsSubmitting(false)
        setMessage({
          type: "success",
          text: "Your sign up request has been sent! We will be in touch soon.",
        })
        resetReCaptcha(recaptchaInstance)
      })
      .catch(() => {
        setIsSubmitting(false)
        setMessage({
          type: "danger",
          text: "Form failed to send",
        })
        resetReCaptcha(recaptchaInstance)
      })
  }

  return (
    <>
      <form onSubmit={handleSubmit} noValidate>
        <div className="row">
          <div className="col">
            <div className="form-group">
              <input
                onChange={handleChange}
                name="name"
                className="form-control"
                placeholder="Name"
                aria-label="Full Name"
              />
              <Feedback message={errors.name} />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="form-group">
              <input
                onChange={handleChange}
                name="phone"
                className="form-control"
                placeholder="Phone"
                aria-label="Phone Number"
              />
              <Feedback message={errors.phone} />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="form-group">
              <input
                onChange={handleChange}
                name="email"
                className="form-control"
                placeholder="E-Mail"
                aria-label="Email Address"
              />
              <Feedback message={errors.email} />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="form-group">
              <textarea
                onChange={handleChange}
                name="address"
                className="form-control"
                rows="2"
                placeholder="Address"
                aria-label="Residential Address"
              />
              <Feedback message={errors.address} />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="form-group">
              <label htmlFor="form-check">Postage Method:</label>
              <div className="form-check">
                <input
                  onChange={handleChange}
                  id="email"
                  name="method"
                  value="email"
                  className="form-check-input"
                  type="radio"
                  aria-label="Postage Method - Email"
                />
                <label htmlFor="email" className="form-check-label">
                  Email
                </label>
                <br />
                <input
                  onChange={handleChange}
                  id="post"
                  name="method"
                  value="post"
                  className="form-check-input"
                  type="radio"
                  aria-label="Postage Method - Post"
                />
                <label htmlFor="post" className="form-check-label">
                  Post
                </label>
              </div>
              <Feedback message={errors.method} />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="form-group">
              <div className="form-check">
                <input
                  onChange={handleCheckboxChange}
                  id="email"
                  name="upcoming"
                  className="form-check-input"
                  type="checkbox"
                  aria-label="Inform me on upcoming events"
                />
                <label htmlFor="email" className="form-check-label">
                  Inform me on upcoming events
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="form-group">
              <textarea
                onChange={handleChange}
                name="suggestions"
                className="form-control"
                rows="5"
                placeholder="Suggestions"
                aria-label="Suggestions"
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col text-left">
            <div className="form-group">
              <Recaptcha
                ref={(e) => (recaptchaInstance = e)}
                sitekey={recaptchaKey}
                render="explicit"
                onloadCallback={() => {}}
                verifyCallback={(response) => {
                  handleReCaptcha(response)
                }}
              />
              <Feedback message={errors.reCaptcha} />
            </div>
            <Message type={message.type} message={message.text} />
            <div className="form-group">
              <p className="form-text">
                A representative from Stuarts Coaches will contact you to
                arrange payment
              </p>
            </div>
            <div className="form-group">
              {isSubmitting ? (
                <Button type="submit" disabled bsPrefix="stu">
                  Sending <Icon prefix="fas" name="spinner" spin />
                </Button>
              ) : (
                <Button type="submit" onClick={handleSubmit} bsPrefix="stu">
                  Send <Icon prefix="fas" name="chevron-right" />
                </Button>
              )}
            </div>
          </div>
        </div>
      </form>
    </>
  )
}

export default TravelClubForm
