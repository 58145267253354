import React from "react"

import Layout from "../../components/Layout"
import TimetableNotice from "../../components/Content/TimetableNotice"
import Heading from "../../components/Layout/heading"
import TravelClubForm from "../../components/Forms/travel-club"

import SEO from "../../components/SEO"

const TravelClub = (props) => (
  <Layout location={props.location}>
    <SEO
      title="Travel Club"
      description="Formed in 1995, with the motto of Service, Safety and Satisfaction."
    />
    <TimetableNotice />
    <div className="row">
      <div className="col-12 col-lg-8">
        <Heading title="What is the Travel Club?" />
        <p className="sub-heading">
          Formed in 1995, with the motto of Service, Safety &amp; Satisfaction.
        </p>
        <p>
          The Travel Club is aimed at group travel for those who do not wish to
          travel on their own, or for those wanting to meet new people and form
          new friendships.
        </p>
        <p>
          The Travel Club now has a membership of over 2,500 members, enjoying
          each other’s company and meeting new friends travelling to new places,
          fun mystery days, extended tours Australia wide also Norfolk Island,
          Lord Howe, some oversea travel to New Zealand, Cook Islands, Fiji
        </p>
        <p className="sub-heading">Membership Benefits:</p>
        <p>
          3 Newsletters per year detailing priority booking for upcoming Mystery
          Tours, Day Tours &amp; Extended Tours
        </p>
        <p>
          <b>Cost:</b> $15 one off fee for Single or Family.
        </p>
      </div>
      <div className="col-12 col-lg-4">
        <Heading title="Sign Up!" />
        <TravelClubForm />
      </div>
    </div>
  </Layout>
)

export default TravelClub
